import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const MaterialGrid = styled(Grid)`
`
export const MaterialGridInst = styled(Grid)`
  padding: 10px 2px;
  &.bloco_convenios {
    margin-top: 10px;
  }
  &.align{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &.serdil{
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`
export const InstInfoTopPage = styled.span`
  font-family: Signika;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #444444 !important;

  p {
    font-family: Signika;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #444444 !important;
  }
`

export const InstitucionalDescricaoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* padding: 20px 0; */
  flex-direction: column;
  flex-wrap: wrap;
`
export const BlocosInfoNum = styled.div`
  font-family: Signika;
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #018391;
`
export const Image = styled.img`
  text-align: center;
  align-items: center;
  width: 90%;
`
export const BlocosInfoTitle = styled.div`
font-family: Signika;
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 28px;
text-align: center;
color: #444444;
`

export const BlocosInfoText = styled.div`
font-family: Signika;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 24px;
text-align: center;
color: #666666;
&.texto{
  text-align: left;
}
`
export const ImgHide = styled.div`
&.serdil{
  padding: 0px 20px;
  align-items: center;
}

@media screen and (min-width: 600px) {
  display: none;
  } 
`
export const ImgShow = styled.div`
&.weinmann{
  padding-left: 10px;
}
@media screen and (max-width: 600px) {
  display: none;
  }
`
