import { Body, Col, Grid, Row, Separator } from 'atomic'
import { graphql, Link } from 'gatsby'
import * as React from 'react'
import { ListNoBullets } from '@components/atm.list-no-bullets/ListNoBullets.component'
import {
  TextListItemStyled,
  TextListItemTextStyled
} from '@components/atm.text-list-item/text-list-item.style'
import { SEO } from '@components/legacy/mol.seo/seo.component'
import TitleWithBreadcrumbRow from '@components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { Query } from '@root/src/data/graphql/graphql-types'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl, getPathUrl } from 'utils/path'
import { getListJsonLd } from '@root/src/components/legacy/mol.seo/build-list-json-ld'
import { getDetailPageUrl, isDoctorUrl } from 'utils/url'
import { Container } from '@material-ui/core'
import {
  MaterialGrid,
  BlocosInfoNum,
  BlocosInfoText,
  BlocosInfoTitle,
  ImgHide,
  ImgShow,
  InstInfoTopPage,
  InstitucionalDescricaoContainer,
  MaterialGridInst,
  Image
} from './institutional-list.style'
import Title from '@root/src/components/Title'
interface AboutItem {
  title: string
  url: string
}
export interface InstitutionalListTemplateProps {
  list: AboutItem[]
}

const InstitutionalListTemplate: React.FunctionComponent<PageProps<
  InstitutionalListTemplateProps,
  Query
>> = props => {
  const staticData = props.data.cosmicjsInformacoesEstaticas
  const isDoctor = isDoctorUrl(props.location.pathname)
  const siteUrl = props.data.site.siteMetadata.siteUrl
  const localPath = appPaths.institutional.path
  return (
    <ClientDoctorSelectContext.Provider
      value={{ clientUrl: localPath.clientUrl, doctorUrl: localPath.doctorUrl }}
    >
      <IndexLayout location={props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(siteUrl, localPath),
            title: staticData.metadata.seo.titulo,
            image: staticData.metadata.seo.imagem.url,
            imageAlt: staticData.metadata.seo.imagemAlternativa,
            description: staticData.metadata.seo.descricao
          }}
          jsonld={getListJsonLd(
            props.pageContext.list.map(item => ({
              url: getDetailPageUrl(getPathUrl(localPath, isDoctor), item.url)
            }))
          )}
        />
        {process.env.GATSBY_COSMIC_BUCKET.toLowerCase() === 'weinmann' ? (
          <>
            <MaterialGrid item lg={12}>
              <ImgHide>
                <img src={staticData.metadata.imagem_new_top.imgix_url} width={'100%'} />
              </ImgHide>
            </MaterialGrid>

            <Container maxWidth="md">
              <TitleWithBreadcrumbRow />

              <MaterialGrid container>
                <MaterialGrid item xs={12} sm={6}>
                  <InstitucionalDescricaoContainer>
                    <InstInfoTopPage>
                      <Title className="newLayout">{staticData.metadata.titulo}</Title>
                      <div
                        dangerouslySetInnerHTML={{ __html: staticData.metadata.descricaoHTML }}
                      />
                    </InstInfoTopPage>
                  </InstitucionalDescricaoContainer>
                </MaterialGrid>
                <MaterialGrid item xs={12} sm={6}>
                  <InstitucionalDescricaoContainer>
                    <ImgShow className='weinmann'>
                      <img src={staticData.metadata.imagem_new.imgix_url} width={'100%'} />
                    </ImgShow>
                  </InstitucionalDescricaoContainer>
                </MaterialGrid>
              </MaterialGrid>
              <Separator />
              <MaterialGrid container>
                <MaterialGridInst item xs={12} sm={4}>
                  <BlocosInfoNum>
                    <img src={staticData.metadata.bloco_unidades.logo_unidade.url} />
                  </BlocosInfoNum>
                  <BlocosInfoNum>
                    {staticData.metadata.bloco_unidades.numero_de_unidades}
                  </BlocosInfoNum>
                  <BlocosInfoTitle>
                    {staticData.metadata.bloco_unidades.titulo_unidade}
                  </BlocosInfoTitle>
                  <BlocosInfoText>
                    {staticData.metadata.bloco_unidades.texto_unidade}
                  </BlocosInfoText>
                </MaterialGridInst>
                <MaterialGridInst className="bloco_convenios" item xs={12} sm={4}>
                  <BlocosInfoNum>
                    <img src={staticData.metadata.bloco_convenios.logo_convenios.url} />
                  </BlocosInfoNum>
                  <BlocosInfoNum>
                    {staticData.metadata.bloco_convenios.numero_de_convenios}
                  </BlocosInfoNum>
                  <BlocosInfoTitle>
                    {staticData.metadata.bloco_convenios.titulo_convenios}
                  </BlocosInfoTitle>
                  <BlocosInfoText>
                    {staticData.metadata.bloco_convenios.texto_convenios}
                  </BlocosInfoText>
                </MaterialGridInst>
                <MaterialGridInst item xs={12} sm={4}>
                  <BlocosInfoNum>
                    <img src={staticData.metadata.bloco_exame.logo_exames.url} />
                  </BlocosInfoNum>
                  <BlocosInfoNum>{staticData.metadata.bloco_exame.numero_de_exames}</BlocosInfoNum>
                  <BlocosInfoTitle>{staticData.metadata.bloco_exame.titulo_exames}</BlocosInfoTitle>
                  <BlocosInfoText>{staticData.metadata.bloco_exame.texto_exames}</BlocosInfoText>
                </MaterialGridInst>
              </MaterialGrid>
              <Separator />

              <MaterialGrid container>
                <MaterialGridInst className="align" item xs={12} sm={5}>
                  <Image src={staticData.metadata.marcas_associadas.marca_a.url} />
                </MaterialGridInst>
                <MaterialGridInst item xs={12} sm={7}>
                  <Title className="newLayout">
                    {staticData.metadata.marcas_associadas.titulo_marca_a}
                  </Title>
                  <BlocosInfoText className="texto">
                    {staticData.metadata.marcas_associadas.texto_marca_a}
                  </BlocosInfoText>
                </MaterialGridInst>
                
                
                <ImgHide className='serdil'>
                  <Separator />
                  <Image className="serdil" src={staticData.metadata.marcas_associadas.marca_b.url}/>
                </ImgHide>
                <MaterialGridInst item xs={12} sm={8}>
                  <Title className="newLayout">
                    {staticData.metadata.marcas_associadas.titulo_marca_b}
                  </Title>
                  <BlocosInfoText className="texto">
                    {staticData.metadata.marcas_associadas.texto_marca_b}
                  </BlocosInfoText>
                </MaterialGridInst>
                <MaterialGridInst className="serdil" item xs={12} sm={4}>
                  <ImgShow>
                    <Image src={staticData.metadata.marcas_associadas.marca_b.url} />
                  </ImgShow>
                </MaterialGridInst>
              </MaterialGrid>
            </Container>
            <Separator />
          </>
        ) : (
          <Grid>
            <TitleWithBreadcrumbRow title={staticData.metadata.titulo}>
              <Body>
                <div dangerouslySetInnerHTML={{ __html: staticData.metadata.descricaoHTML }} />
              </Body>
            </TitleWithBreadcrumbRow>
            <Row>
              <Col xs={12}>
                <ListNoBullets id="list-institucional">
                  {props.pageContext.list.map(item => (
                    <Link
                      to={getDetailPageUrl(getPathUrl(localPath, isDoctor), item.url)}
                      key={item.title}
                    >
                      <TextListItemStyled>
                        <TextListItemTextStyled>{item.title}</TextListItemTextStyled>
                      </TextListItemStyled>
                    </Link>
                  ))}
                </ListNoBullets>
              </Col>
            </Row>
            <Separator />
          </Grid>
        )}
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default InstitutionalListTemplate

export const query = graphql`
  query InstitutionalListTemplate {
    site {
      siteMetadata {
        siteUrl
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "institucional" }) {
      metadata {
        titulo
        descricaoHTML
        imagem_new {
          url
          imgix_url
        }
        imagem_new_top {
          url
          imgix_url
        }
        bloco_unidades {
          logo_unidade {
            url
            imgix_url
          }
          numero_de_unidades
          titulo_unidade
          texto_unidade
        }
        bloco_convenios {
          logo_convenios {
            url
            imgix_url
          }
          numero_de_convenios
          titulo_convenios
          texto_convenios
        }
        bloco_exame {
          logo_exames {
            url
            imgix_url
          }
          numero_de_exames
          titulo_exames
          texto_exames
        }
        marcas_associadas {
          marca_a {
            url
            imgix_url
          }
          marca_b {
            url
            imgix_url
          }
          titulo_marca_a
          titulo_marca_b
          texto_marca_a
          texto_marca_b
        }
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
      }
    }
  }
`
