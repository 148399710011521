import { Color, H4Style, Spacing, TransitionDuration } from 'atomic'
import styled from 'styled-components'

export const TextListItemStyled = styled.li`
  ${H4Style}
  color: ${Color.GrayXDark};
  display: flex;
  cursor: pointer;
  margin: 0;
  padding: ${Spacing.Small};
  transition: all ${TransitionDuration};

  :hover {
    background: ${Color.GrayLight};
  }
`

export const TextListItemTextStyled = styled.span`
  display: inline-block;
`
